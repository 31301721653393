html {
  height: 100%
}
body {
  height: calc(100% - .1em);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
